import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResult } from '../model/api-result';
import { QuoteParameters } from '../model/quote-parameters';
import { QuoteRequest } from '../model/quote-request';
import {map} from 'rxjs/operators';

@Injectable()
export class QuickQuoteHttpService {

    constructor(private http: HttpClient) {
    }

    validateAddress(data) {
        return this.http.post('ui/quickQuote/addressValidation',
            data,
            {responseType: 'json', observe: 'response'})
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    };

    listHomeBuilders() {
        return this.http.post('ui/quickQuote/listHomeBuilders', {})
    }

    ratePolicy(data: QuoteParameters) {
        return this.http.post<ApiResult>('ui/quickQuote/ratePolicy',
            data,
            {responseType: 'json', observe: 'response'})
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    };

    contactInfo(data: QuoteRequest) {
        return this.http.post<ApiResult>('ui/quickQuote/contactInfo',
            data,
            {responseType: 'json', observe: 'response'})
            .pipe(
                map(res => {
                    return res.body;
                })
            );
    };
}
