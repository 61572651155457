import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AppSettings} from "../../../model/constants/app-settings";

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit{

    contactAgentUrl = AppSettings.CONTACT_AGENT_URL;

    constructor(private router: Router) { }

    restart() {
        this.router.navigateByUrl("/");
    }

    contactAgent() {
        this.router.navigate(['/externalRedirect', { externalUrl: this.contactAgentUrl, target: self }], {
            skipLocationChange: true,
        });
    }

    ngOnInit(): void {}

}
