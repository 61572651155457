import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {Messages} from "../../../model/constants/messages";
import {AlertService} from "../../../core/alert/alert.service";
import {UtilsService} from "../../../core/utils.service";
import {WorkflowService} from "../../workflow.service";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {QuoteRequest} from "../../../model/quote-request";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {OCCUPANCY, PREFERRED_CONTACT_METHOD} from "../../../model/dropdown-enums";
import {RateResponse} from "../../../model/rate-response";

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContactInfoFormComponent implements OnInit, OnDestroy {
  quoteRequest: QuoteRequest = new QuoteRequest();
  contactInfoForm: FormGroup;
  formType: string;
  preferredContactMethod = PREFERRED_CONTACT_METHOD;
  firstNameErrorMessage = Messages.FIRST_NAME_ERROR;
  lastNameErrorMessage = Messages.LAST_NAME_ERROR;
  emailErrorMessage = Messages.EMAIL_ERROR;
  phoneErrorMessage = Messages.PHONE_ERROR;
  dateErrorMessage = Messages.DATE_ERROR;
  private subscriptions = new Subscription();
  rateResponse: RateResponse;


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private alertService: AlertService,
              private utils: UtilsService,
              private workflowService: WorkflowService,
              private storeService: QuickQuoteStoreService,) {
    this.formType = this.router.getCurrentNavigation().extras.state.formType;
  }

  ngOnInit() {
    this.buildContactInfoForm();
    const quoteRequest: QuoteRequest = this.storeService.quoteRequest.getValue();
    this.rateResponse = quoteRequest.rateResponse;
    this.contactInfoForm.patchValue(this.storeService.quoteRequest.getValue().contactParameters);
    window.scrollTo(0, 0);
    this.subscriptions.add(
      this.storeService.quoteRequest.subscribe(qr => {
        this.quoteRequest = qr;
      })
    );
  }

  buildContactInfoForm() {
    this.contactInfoForm = this.formBuilder.group({
      "emailAddress": ['', [
        Validators.required,
        Validators.email]],
      "firstName": ['', Validators.required],
      "lastName": ['', Validators.required],
      "phoneNumber": ['', [
        Validators.required,
        Validators.minLength(14),
        Validators.maxLength(14)]], //length includes mask
      "preferredContactMethod": ['', Validators.required],
    });
  }

  nextStep(): void {
    this.contactInfoForm.markAllAsTouched();
    this.contactInfoForm.value['phoneNumber'] = this.contactInfoForm.value['phoneNumber'].replace(/\D/g, '');
    if (!this.storeService.validateForm(this.contactInfoForm, Messages.FORM_ERROR)) return;
    this.subscriptions.add(
      this.storeService.updateContactParameters(this.contactInfoForm.value)
        .subscribe((data: any) => {
          this.workflowService.triggerNext();
        })
    );
  }

  submitToAgent(): void {
    this.alertService.clear();
    // stop here if form is invalid
    this.contactInfoForm.value['phoneNumber'] = this.contactInfoForm.value['phoneNumber'].replace(/\D/g, '');
    if (!this.storeService.validateForm(this.contactInfoForm, Messages.FORM_ERROR)) return;

    this.subscriptions.add(this.storeService.updateContactParameters(this.contactInfoForm.value)
      .subscribe((data: any) => {
        this.workflowService.triggerSubmit();
      })
    );
  }

  previousStep(): void {
    this.workflowService.triggerPrev();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
