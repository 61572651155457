import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from "./shared-modules/material.module";

import {AppRoutingModule} from './app-routing.module';
import {QuickQuoteModule} from './quick-quote/quick-quote.module';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QuickQuoteHttpService} from './services/quick-quote-http.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './home/home.component';
import {QuickQuoteStoreService} from './services/quick-quote-store.service';
import {AlertComponent} from './core/alert/alert.component';
import {AlertService} from './core/alert/alert.service';
import {AddressOptionStore} from './services/address-option-store';
import {Header2Component} from "./header2/header2.component";
import {FooterComponent} from "./footer/footer.component";
import {ErrorHandlerComponent} from "./core/error-handler/error-handler/error-handler.component";
import {SpinnerComponent} from "./core/spinner/spinner.component";
import {HttpConfigInterceptor} from "./core/http-interceptor/http-interceptor";
import {DynamicScriptLoaderService} from "./services/dynamic-script-loader.service";
import {DialogPopoverComponent} from "./dialog-popover/dialog-popover.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {ExternalUrlDirective} from "./directives/external-url.directive";
import {UtilsService} from "./core/utils.service";
import {ErrorPageComponent} from "./core/error-handler/error-page/error-page.component";
import {WorkflowService} from "./quick-quote/workflow.service";


@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        HeaderComponent,
        FooterComponent,
        Header2Component,
        HomeComponent,
        DialogPopoverComponent,
        ErrorHandlerComponent,
        ErrorPageComponent,
        SpinnerComponent,
        NotFoundComponent,
        ExternalUrlDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        QuickQuoteModule
    ],
    entryComponents: [
        DialogPopoverComponent,
        ErrorHandlerComponent,
        ErrorPageComponent,
        SpinnerComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        AddressOptionStore,
        AlertService,
        UtilsService,
        QuickQuoteStoreService,
        QuickQuoteHttpService,
        WorkflowService,
        DynamicScriptLoaderService,

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
