import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {initialUiState, UiState} from "../../model/UiState";
import {Messages} from "../../model/constants/messages";

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {
    public status: BehaviorSubject<UiState> = new BehaviorSubject(initialUiState);

    constructor() { }

    startBackendAction(message:string = Messages.STANDARD_SPINNER_MESSAGE) {
        this.status.next({
            actionOngoing: true,
            message
        });
        // console.log('startBackendAction called', this.status.getValue());
    }

    endBackendAction() {
        this.status.next({
            actionOngoing: false,
            message: ''
        });
        // console.log('endBackendAction called', this.status.getValue());
    }
}
