import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import {Messages} from "../../../model/constants/messages";

@Component({
    selector: 'app-error-handler',
    templateUrl: './error-handler.component.html',
    styleUrls: ['./error-handler.component.scss']
})
export class ErrorHandlerComponent implements OnInit {

    serverErrorMessage = Messages.SERVER_ERROR;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

    ngOnInit() {
    }

}
