import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class WorkflowService {
  private _nextTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _prevTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _submitTriggered: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get nextTriggered() {
    return this._nextTriggered;
  }

  get prevTriggered() {
    return this._prevTriggered;
  }

  get submitTriggered() {
    return this._submitTriggered;
  }

  triggerNext() {
    this._nextTriggered.next(true);
  }

  triggerPrev() {
    this._prevTriggered.next(true);
  }

  triggerSubmit() {
    this._submitTriggered.next(true);
  }
}
