import {Injectable} from "@angular/core";
import {QuickQuoteHttpService} from "./quick-quote-http.service";
import {QuoteParameters} from "../model/quote-parameters";
import {BehaviorSubject, Observable, of} from "rxjs";
import {QuoteRequest} from "../model/quote-request";
import {ContactParameters} from "../model/contact-parameters";
import {map} from "rxjs/operators";
import {ApiResult} from "../model/api-result";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {AlertService} from "../core/alert/alert.service";

@Injectable()
export class QuickQuoteStoreService {

    private _quoteRequest: BehaviorSubject<QuoteRequest> = new BehaviorSubject(new QuoteRequest());

    get quoteRequest() {
        return this._quoteRequest;
    }

    constructor(private httpService: QuickQuoteHttpService, private alertService: AlertService) {
    }

    loadInitialData(agencyCode: String, formCode: String) {
        const qr = new QuoteRequest();
        qr.quoteParameters.agencyCode = agencyCode;
        qr.quoteParameters.form = formCode;
        this._quoteRequest.next(qr);
    }

    updateAddress(data: any) {
        let qr = this._quoteRequest.getValue();
        qr.quoteParameters = {...qr.quoteParameters, ...data};
        this._quoteRequest.next(qr);
    }

    updateQuoteParameters(data: QuoteParameters, rate: boolean = false): Observable<ApiResult> {
        let qr = this._quoteRequest.getValue();
        let qp: QuoteParameters = {...qr.quoteParameters, ...data};
        qr.quoteParameters = qp;
        if (rate) {
            return this.rate(qp);
        } else {
            this._quoteRequest.next(qr);
            return of(null);
        }
    }

    private rate(qp: QuoteParameters): Observable<ApiResult> {
        let qr = this._quoteRequest.getValue();

        return this.httpService.ratePolicy(qp)
          .pipe(map(response => {
          qr.rateResponse = {...qr.rateResponse, ...response.data.rateResponse};
          qr.quoteParameters.policyTermId = response.data.rateResponse.policyTermId;
          this._quoteRequest.next(qr);
          return response;
        }));
    }

    updateContactParameters(data: ContactParameters): Observable<ApiResult> {
        let qr = this._quoteRequest.getValue();
        qr.contactParameters = {...qr.contactParameters, ...data};

        return this.httpService.contactInfo(qr)
            .pipe(map(response => {
              qr = {...qr, ...response.data};
              this._quoteRequest.next(qr);
              return response;
            }));
    }

  validateForm(currentForm: FormGroup, errorMessage: string): boolean {
    if (currentForm !== undefined && currentForm.invalid) {
      this.alertService.error(errorMessage);
      this.getFormValidationErrors(currentForm);
      return false;
    }
    return true;
  }

  getFormValidationErrors(currentForm: FormGroup) {
    console.warn('Form Invalid!');
    Object.keys(currentForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = currentForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.warn('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
}
