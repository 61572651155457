import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {QuickQuoteHttpService} from "../../../services/quick-quote-http.service";
import {QuoteParameters} from "../../../model/quote-parameters";
import {Observable, Subscription} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {Messages} from "../../../model/constants/messages";
import {AppSettings} from "../../../model/constants/app-settings";
import {DropdownItem} from "../../../model/dropdown-item";
import * as moment from "moment";
import {AlertService} from "../../../core/alert/alert.service";
import {UtilsService} from "../../../core/utils.service";
import {WorkflowService} from "../../workflow.service";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {QuoteRequest} from "../../../model/quote-request";
import {RateResponse} from "../../../model/rate-response";

@Component({
  selector: 'app-refine-quote2-form',
  templateUrl: './refine-quote2-form.component.html',
  styleUrls: ['./refine-quote2-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefineQuote2FormComponent implements OnInit, OnDestroy {
  refineQuote2Form: FormGroup;
  nextButtonDisabled: boolean = true;
  @Input() quoteParameters: QuoteParameters;
  homeBuilders: any;
  dateErrorMessage = Messages.FUTURE_DATE_ERROR;
  buildersErrorMessage = Messages.BUILDERS_ERROR;
  privacyStatementUrl = AppSettings.PRIVACY_STATEMENT_URL;
  filteredBuilders: Observable<DropdownItem[]>;
  private subscriptions = new Subscription();
  rateResponse: RateResponse;

  constructor(private httpService: QuickQuoteHttpService,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private utils: UtilsService,
              private workflowService: WorkflowService,
              private storeService: QuickQuoteStoreService,) {
  }

  dateFilter = (selectedDate: Date) => {
    let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(new Date);
    return selectedDate >= yesterday;
  };

  ngOnInit() {
    this.buildRefineQuote2Form();
    const quoteRequest: QuoteRequest = this.storeService.quoteRequest.getValue();
    this.rateResponse = quoteRequest.rateResponse;
    this.refineQuote2Form.patchValue(this.storeService.quoteRequest.getValue().quoteParameters);
    window.scrollTo(0, 0);
    this.subscriptions.add(
      this.httpService.listHomeBuilders().subscribe(response => {
        this.homeBuilders = response['data'];
        this.filterHomeBuilders();
      })
    );
  }

  buildRefineQuote2Form() {
    this.refineQuote2Form = this.formBuilder.group({
      "nearFireHydrant": ['', Validators.required],
      "knowCompanyName": ['', Validators.required],
      "homeBuilders": [''], // Displays autocomplete
      "homeBuilderId": [''],// Placeholder for QuoteParameters value
      "effectiveDate": ['', [
        Validators.required,
        this.DateValidator()]],
      "dateOfBirth": ['', [
        Validators.required,
        this.DateValidator()]],
      "personalInformationConsent": ['', Validators.required]
    });
    this.setHomBuildersValidators();
    this.subscriptions.add(
      this.refineQuote2Form.get('personalInformationConsent').valueChanges.subscribe(value => {
        this.nextButtonDisabled = value === 'true' ? false : true;
      })
    );
  }

  DateValidator(format = "MM/dd/YYYY"): any {
    return (control: FormControl): { [key: string]: any } => {
      const val = moment(control.value, format, true);

      if (!val.isValid()) {
        return {invalidDate: true};
      }

      return null;
    };
  }

  setHomBuildersValidators() {
    const homeBuildersControl = this.refineQuote2Form.get('homeBuilders');
    const knowCompanyNameControl = this.refineQuote2Form.get('knowCompanyName');

    this.subscriptions.add(
      knowCompanyNameControl.valueChanges.subscribe(val => {
        if (val === 'true') {
          homeBuildersControl.setValidators([Validators.required]);
        } else {
          homeBuildersControl.setValidators(null);
          homeBuildersControl.setValue(null);
        }
        homeBuildersControl.updateValueAndValidity();
      })
    );
  }

  filterHomeBuilders() {
    this.filteredBuilders = this.refineQuote2Form.controls['homeBuilders']
      .valueChanges
      .pipe(
        startWith(''),
        map(builder => typeof builder === 'string' ? builder : builder.label),
        map(label => label ? this._filter(label) : this.homeBuilders.slice())
      );
  }

  displayFn(builder?: DropdownItem): string | undefined {
    return builder ? builder.label : undefined;
  }

  persistBuilder(builder: DropdownItem) {
    this.refineQuote2Form.controls['homeBuilderId'].setValue(builder.val);
  }

  nextStep(): void {
    this.alertService.clear();
    // stop here if form is invalid
    if (!this.storeService.validateForm(this.refineQuote2Form, Messages.FORM_ERROR)) return;
    this.subscriptions.add(
      this.storeService.updateQuoteParameters(this.refineQuote2Form.value, true)
        .subscribe((data: any) => {
          this.workflowService.triggerSubmit();
        })
    );
  }

  previousStep(): void {
    this.workflowService.triggerPrev();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private _filter(name: string): DropdownItem[] {
    const filterValue = name.toLowerCase();
    return this.homeBuilders.filter(option => option.label.toLowerCase().indexOf(filterValue) === 0);
  }
}
