export const CONSTRUCTION_TYPES = {
    FR: 'Fire Resistive',
    F: 'Frame',
    FH: 'Frame w/Hardiplank',
    M: 'Masonry',
    MV: 'Masonry Veneer',
    SMNC: 'Superior Masonry Non-Combustible',
    SNC: 'Superior Non-Combustible'
};

export const RESIDENCE_TYPES = {
    SF: 'Single Family',
    T: 'Townhome/Rowhome',
    MF: 'Multi Family 2-4',
};

export const TOWNHOUSE_OR_ROWHOUSE_TYPES = {
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4',
    _5: '5',
    _6: '6',
    _7: '7',
    _8: '8',
};

export const OCCUPIED_BY_TYPES = {
    O: 'Owner',
    T: 'Tenant',
    OT: 'Owner and Tenant',
};

export const NUMBER_OF_STORIES = {
    _1: '1',
    _1_5: '1.5',
    _2: '2',
    _2_5: '2.5',
    _3: '3',
    _3_5: '3.5'
};

export const OCCUPANCY = {
    P: 'Primary',
    E: 'Secondary'
};

export const PREFERRED_CONTACT_METHOD = {
    EMAIL: 'Email',
    PHONE: 'Phone'
};

export const ROOFING_MATERIAL = {
    TB: 'Concrete/Clay Tile - Barrel Tiles',
    TF: 'Concrete/Clay Tile - Flat Tiles',
    TO: 'Concrete/Clay Tile - Other Tiles',
    M: 'Metal',
    R: 'Rated Shingle (110 mph)',
    S: 'Standard Shingle',
    G: 'Tar and Gravel',
    W: 'Wood Shingle',
    O: 'Other'
};

export const ROOF_SHAPE = {
    F: 'Flat',
    G: 'Gable',
    H: 'Hip',
    O: 'Other'
};

export const  US_STATES = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
};



