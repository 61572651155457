import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AppSettings} from "../model/constants/app-settings";

@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class Header2Component implements OnInit {

  thigUrl = AppSettings.THIG_URL;
  customerCarePhone = AppSettings.CUSTOMER_CARE_PHONE_NUMBER;
  customerServiceEmail = AppSettings.CUSTOMER_SERVICE_EMAIL;

  constructor() { }

  ngOnInit() {
  }

}
