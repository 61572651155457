import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    durationInSeconds = 5;
    snackBarRef: any;

    constructor(private snackBar: MatSnackBar, private router: Router) {
        router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (this.snackBarRef) {
                this.snackBarRef.dismiss();
            }
        });
    }

    // This can be used if we want to switch back to a pop-up error message for http errors
    errorBar(res): void {
        this.snackBarRef = this.snackBar.openFromComponent(ErrorHandlerComponent, {
            duration: this.durationInSeconds * 1000,
            data: res,
            verticalPosition: 'top',
            panelClass: 'error'
        });
    }

    handleError(res): void {
        this.router.navigateByUrl("/error");
    }

}
