import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {QuickQuoteHttpService} from "../../../services/quick-quote-http.service";
import {QuoteParameters} from "../../../model/quote-parameters";
import {NUMBER_OF_STORIES, OCCUPANCY, ROOF_SHAPE, ROOFING_MATERIAL} from "../../../model/dropdown-enums";
import {DialogPopoverComponent} from "../../../dialog-popover/dialog-popover.component";
import {MatDialog} from "@angular/material";
import {Messages} from "../../../model/constants/messages";
import * as moment from "moment";
import {AlertService} from "../../../core/alert/alert.service";
import {UtilsService} from "../../../core/utils.service";
import {WorkflowService} from "../../workflow.service";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {Subscription} from "rxjs";
import {QuoteRequest} from "../../../model/quote-request";
import {RateResponse} from "../../../model/rate-response";

@Component({
  selector: 'app-refine-quote1-form',
  templateUrl: './refine-quote1-form.component.html',
  styleUrls: ['./refine-quote1-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RefineQuote1FormComponent implements OnInit, OnDestroy {
  refineQuote1Form: FormGroup;
  @Input() quoteParameters: QuoteParameters;
  numberOfStoriesList = NUMBER_OF_STORIES;
  roofMaterials = ROOFING_MATERIAL;
  roofShapes = ROOF_SHAPE;
  occupancies = OCCUPANCY;
  selectErrorMessage = Messages.SELECT_ERROR;
  dateErrorMessage = Messages.DATE_ERROR;
  private subscriptions = new Subscription();
  rateResponse: RateResponse;

  constructor(private httpService: QuickQuoteHttpService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private alertService: AlertService,
              private utils: UtilsService,
              private workflowService: WorkflowService,
              private storeService: QuickQuoteStoreService,) {
  }

  ngOnInit() {
    this.buildRefineQuote1Form();
    const quoteRequest: QuoteRequest = this.storeService.quoteRequest.getValue();
    this.rateResponse = quoteRequest.rateResponse;
    this.refineQuote1Form.patchValue(this.storeService.quoteRequest.getValue().quoteParameters);
    window.scrollTo(0, 0);

  }

  buildRefineQuote1Form() {
    this.refineQuote1Form = this.formBuilder.group({
      "numberOfStories": ['', Validators.required],
      "roofingMaterial": ['', Validators.required],
      "roofShape": ['', Validators.required],
      "occupancy": ['', Validators.required],
      "newPurchase": ['', Validators.required],
      "priorPolicyExpiration": ['', '']
    });
    this.setPriorPolicyExpirationValidators();
  }

  DateValidator(format = "MM/dd/YYYY"): any {
    return (control: FormControl): { [key: string]: any } => {
      const val = moment(control.value, format, true);

      if (!val.isValid()) {
        return {invalidDate: true};
      }

      return null;
    };
  }

  setPriorPolicyExpirationValidators() {
    const priorPolicyExpirationControl = this.refineQuote1Form.get('priorPolicyExpiration');
    const newPurchaseControl = this.refineQuote1Form.get('newPurchase');

    this.subscriptions.add(
      newPurchaseControl.valueChanges.subscribe(val => {
        if (val === 'false') {
          priorPolicyExpirationControl.setValidators([Validators.required, this.DateValidator()]);
        } else {
          priorPolicyExpirationControl.setValidators(null);
          priorPolicyExpirationControl.setValue(null);
        }
        priorPolicyExpirationControl.updateValueAndValidity();
      })
    )
  }

  openPopOver(formControlName: String): void {
    this.dialog.open(DialogPopoverComponent, {
      maxWidth: '450px',
      data: {formControlName: formControlName}
    });
  }

  nextStep(): void {
    this.alertService.clear();
    // stop here if form is invalid
    if (!this.storeService.validateForm(this.refineQuote1Form, Messages.FORM_ERROR)) return;
    this.subscriptions.add(
      this.storeService.updateQuoteParameters(this.refineQuote1Form.value, false)
        .subscribe((data: any) => {
          this.workflowService.triggerNext();
        })
    );
  }

  previousStep(): void {
    this.workflowService.triggerPrev();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
