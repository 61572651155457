import {AfterViewInit, Component, EventEmitter, Input, NgZone, Output, ViewChild} from "@angular/core";
import {ThigAddress} from '../model/thig-address';
declare let google: any;

//Source:
//https://github.com/dhormale/google-places-autocomplete/blob/master/src/app/google-places.component.ts

@Component({
    selector: 'ThigAddressAutocomplete',
    template: `
        <mat-form-field class="full-width">
            <input 
                matInput
                placeholder="Address"
                type="text"
                [(ngModel)]="autocompleteInput"
                #addresstext>
        </mat-form-field>
    `,
})
export class ThigAddressAutocompleteComponent implements AfterViewInit {
    @Input() adressType: string;
    @Output() setAddress: EventEmitter<ThigAddress> = new EventEmitter();
    @ViewChild('addresstext',{static:false}) addresstext: any;

    autocompleteInput: string;

    constructor(private zone: NgZone) {
    }

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {

        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                componentRestrictions: { country: 'US' },
                types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.zone.run(() => {
                const place = autocomplete.getPlace();
                this.invokeEvent(place);
            });
        });
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(this.getThigAddress(place));
    }

    getThigAddress(place) {
        //@params: place - Google Autocomplete place object
        //@returns: location_obj - An address object in human readable format
        let location_obj = {};
        for (let i in place.address_components) {
            let item = place.address_components[i];

            location_obj['formatted_address'] = place.formatted_address;
            if(item['types'].indexOf("locality") > -1) {
                location_obj['locality'] = item['long_name']
            } else if (item['types'].indexOf("administrative_area_level_1") > -1) {
                location_obj['admin_area_l1'] = item['short_name']
            } else if (item['types'].indexOf("street_number") > -1) {
                location_obj['street_number'] = item['short_name']
            } else if (item['types'].indexOf("route") > -1) {
                location_obj['route'] = item['long_name']
            } else if (item['types'].indexOf("country") > -1) {
                location_obj['country'] = item['long_name']
            } else if (item['types'].indexOf("postal_code") > -1) {
                location_obj['postal_code'] = item['short_name']
            }
        }

        let thigAddress = new ThigAddress();

        thigAddress.street = location_obj['street_number'] + ' ' + location_obj['route'];
        thigAddress.street2 = '';
        thigAddress.city = location_obj['locality'];
        thigAddress.state = location_obj['admin_area_l1'];
        thigAddress.zipcode = location_obj['postal_code'];
        return thigAddress;
    }

}
