import {ThigAddress} from "./thig-address";

export class QuoteParameters {
    address: ThigAddress;
    agencyCode: String;
    form: String;
    coverageA: String;
    coverageC: String;
    constructionYear: String;
    constructionType: String;
    residenceType: String;
    townhouseOrRowhouse: String;
    occupiedBy: String;
    roofReplaced: boolean = false;
    roofYear: String;
    policyTermId: String;

    numberOfStories: String;
    roofingMaterial: String;
    roofShape: String;
    occupancy: String;
    newPurchase: boolean;
    priorPolicyExpiration: Date;
    nearFireHydrant: boolean;
    homeBuilderId: String;
    effectiveDate: Date;
    personalInformationConsent: boolean;
    dateOfBirth: Date;

    constructor() {
        this.address = new ThigAddress();
        this.agencyCode = '';
        this.form = '';
        this.coverageA = '';
        this.coverageC = '';
        this.constructionYear = '';
        this.roofReplaced = false;
        this.roofYear = '';
        this.policyTermId = '';

        this.constructionType = null;
        this.numberOfStories = null;
        this.roofingMaterial = null;
        this.residenceType = null;
        this.townhouseOrRowhouse = null;
        this.occupiedBy = null;
        this.roofShape = null;
        this.occupancy = null;
        this.newPurchase = true;
        this.priorPolicyExpiration = null;
        this.nearFireHydrant = false;
        this.homeBuilderId = '';
        this.effectiveDate = null;
        this.personalInformationConsent = false;
        this.dateOfBirth = null;
    }
}
