export class ThigAddress {
    city: String = '';
    state: String = '';
    street: String = '';
    street2: String = '';
    zipcode: String = '';

    constructor() {
        this.city = '';
        this.state = '';
        this.street = '';
        this.street2 = '';
        this.zipcode = '';
    }
}