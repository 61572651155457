import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {AddressOption} from "../model/address-option";
import {ThigAddress} from "../model/thig-address";
import {QuoteParameters} from "../model/quote-parameters";
import {QuickQuoteHttpService} from "./quick-quote-http.service";

@Injectable()
export class AddressOptionStore {
    private _addressOptions: BehaviorSubject<AddressOption[]>;
    private dataStore: { addressOptions: AddressOption[] };

    constructor(private httpService: QuickQuoteHttpService) {
        this.initalizeAddressOptions();
    }

    get addressOptions() {
        return this._addressOptions;
    }

    private addAddressOption(addressOption: AddressOption) {
        this.dataStore.addressOptions.push(addressOption);
        this._addressOptions.next(Object.assign({}, this.dataStore).addressOptions);
    }

    private initalizeAddressOptions() {
        this.dataStore = {addressOptions: []};
        this._addressOptions = <BehaviorSubject<AddressOption[]>>new BehaviorSubject([]);
    }

    validateAddress(place: ThigAddress) {
        this.initalizeAddressOptions();
        const googleAddress = new AddressOption(false, place);

        //Create temporary Quote Parameters obj to wrap address and send to backend
        let tempQuoteParameters: QuoteParameters = new QuoteParameters();
        tempQuoteParameters.address = place;

        this.httpService.validateAddress(tempQuoteParameters)
            .subscribe(response => {
            response['data']['candidateAddresses'].map(candidateAddress => {
                const standardAddress = new AddressOption(true, candidateAddress);
                this.addAddressOption(standardAddress);
                this.addAddressOption(googleAddress);
            });
        });
    }
}
