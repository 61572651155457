import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {QuickQuoteHttpService} from '../services/quick-quote-http.service';
import {STEPS} from '../model/workflow-steps';
import {QuickQuoteStoreService} from "../services/quick-quote-store.service";
import {QuoteRequest} from "../model/quote-request";
import {AlertService} from "../core/alert/alert.service";
import {DynamicScriptLoaderService} from "../services/dynamic-script-loader.service";
import {ActivatedRoute} from "@angular/router";
import {AppSettings} from "../model/constants/app-settings";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-quick-quote',
    templateUrl: './quick-quote.component.html',
    styleUrls: ['./quick-quote.component.scss']
})
export class QuickQuoteComponent implements OnInit, OnDestroy {
    quoteRequest: QuoteRequest = new QuoteRequest();
    steps = STEPS;
    ratingForm: FormGroup;
    nextButtonDisabled: boolean = false;
    selectedConstructionType: String = '';
    scriptsLoaded = false;
   private subscriptions = new Subscription();

  constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private httpService: QuickQuoteHttpService,
                private storeService: QuickQuoteStoreService,
                private alert: AlertService,
                private dynamicScriptLoader: DynamicScriptLoaderService) {
      this.loadScripts();
    }

    ngOnInit() {
    this.subscriptions.add(this.storeService.quoteRequest.subscribe(qr => {
      this.quoteRequest = qr;
      this.selectedConstructionType = qr.quoteParameters.constructionType;
    })
    );
    this.initalizeQuoteRequest();
    }

    initalizeQuoteRequest() {
        const paramMap = this.route.snapshot.queryParamMap;
        const agencyCode: String = paramMap.get('agent') ? paramMap.get('agent') : AppSettings.DEFAULT_AGENCY;
        const formCode: String = paramMap.get('form') ? paramMap.get('form').toLocaleUpperCase() : AppSettings.DEFAULT_FORM;
        this.storeService.loadInitialData(agencyCode, formCode);
    }

    loadScripts() {
        if (AppSettings.IS_IE) {
            this.scriptsLoaded = true;
        } else {
            // You can load multiple scripts by just providing the key as argument into load method of the service
            this.dynamicScriptLoader.load('googleMapsApi').then(data => {
                this.scriptsLoaded = true;
            }).catch(error => console.log(error));
        }
    }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
