import {Component, OnInit } from '@angular/core';
import { SpinnerService } from './core/spinner/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Tower Hill - Quick Quote';
  showLoader: boolean;
  spinnerMessage: String;

  constructor(private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.status.subscribe(uiState => {
      setTimeout(() => {
        this.showLoader = uiState.actionOngoing;
        this.spinnerMessage = uiState.message;
      }, 10);
    });
  }

}
