import {ActivatedRoute, Router} from "@angular/router";
import {WorkflowService} from "./workflow.service";
import {QuoteRequest} from "../model/quote-request";
import {Subscription} from "rxjs";

export abstract class WorkFlowComponent {
  protected currentStepIndex = 0;
  protected quoteRequest: QuoteRequest = new QuoteRequest();
  protected subscriptions = new Subscription();

  protected constructor(private router: Router,
                        private route: ActivatedRoute,
                        private workflowService: WorkflowService) {
  }

  ngOnInit() {
    this.router.navigate(['address']);
    this.subscriptions.add(this.workflowService.nextTriggered.subscribe(v => {
        if (v) {
          this.nextStep();
        }
      })
    ).add(
      this.workflowService.prevTriggered.subscribe(v => {
        if (v) {
          this.previousStep();
        }
      })
    ).add(
      this.workflowService.submitTriggered.subscribe(v => {
        if (v) {
          this.showConfirmation();
        }
      })
    );
  }

  getSteps(): string[] {
    return [];
  }

  getFormType(): string {
    return '';
  }

  nextStep() {
    const nextStepRoute = this.getSteps()[this.currentStepIndex + 1];
    this.router.navigate([nextStepRoute],{ state: { formType: this.getFormType() }}).then(success => {
      if (success) {
        this.currentStepIndex = this.currentStepIndex + 1;
      }
    });
  }

  previousStep() {
    const previousStepRoute = this.getSteps()[this.currentStepIndex - 1];
    this.router.navigate([previousStepRoute],{ state: { formType: this.getFormType()}}).then(success => {
      if (success) {
        this.currentStepIndex = this.currentStepIndex - 1;
      }
    });
  }

  showConfirmation() {
    this.router.navigate(['quoteConfirm'], { state: { formType: this.getFormType()}});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
