import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WorkflowService} from "../workflow.service";
import {WorkFlowComponent} from "../work-flow.component";

@Component({
  selector: 'app-condo-work-flow',
  templateUrl: './condo-work-flow.component.html',
  styleUrls: ['./condo-work-flow.component.scss']
})
export class CondoWorkFlowComponent extends WorkFlowComponent {
  public constructor(router: Router,
                     route: ActivatedRoute,
                     workflowService: WorkflowService) {
    super(router, route, workflowService);
  }

  getSteps(): string[] {
    return [
      'address',
      'rating',
      'contact',
      'quoteConfirm'
    ];
  }

  getFormType(): string {
    return 'H6';
  }
}
