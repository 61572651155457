import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from "@angular/core";
import {ThigAddress} from "../../../../model/thig-address";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Messages} from "../../../../model/constants/messages";
import {AlertService} from "../../../../core/alert/alert.service";
import {US_STATES} from "../../../../model/dropdown-enums";

@Component({
    selector: 'thig-address-form',
    templateUrl: './thig-address-form.component.html',
    // styleUrls: ['./thig-address-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ThigAddressFormComponent implements OnInit {
    @Output() setAddress: EventEmitter<ThigAddress> = new EventEmitter();
    thigAddressForm: FormGroup;
    requiredErrorMessage = Messages.REQUIRED_ERROR;
    stateList = US_STATES;

    constructor(private alert: AlertService,
                private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.buildThigAddressForm();
    }

    buildThigAddressForm() {
        this.thigAddressForm = this.formBuilder.group({
            "street": ['', Validators.required],
            "street2": ['', ''],
            "city": ['', Validators.required],
            "state": ['', Validators.required],
            "zipcode": ['', Validators.required]
        });
    }

    validateAddress() {
        if(this.thigAddressForm.valid) {
            let thigAddress = this.thigAddressForm.value;
            console.log('thigAddressComponent', thigAddress)
            this.setAddress.emit(thigAddress as ThigAddress);
        } else {
            this.alert.error(Messages.FORM_ERROR);
        }
    }

    clear() {
        this.thigAddressForm.reset();
    }

}
