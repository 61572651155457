import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "../shared-modules/material.module";
import {AddressFormComponent} from "./forms/address-form/address-form.component";
import {ThigAddressAutocompleteComponent} from "../directives/thig-address-autocomplete.component";
import {RatingFormComponent} from "./forms/rating-form/rating-form.component";
import {ContactInfoFormComponent} from "./forms/contact-info-form/contact-info-form.component";
import {RefineQuote1FormComponent} from "./forms/refine-quote1-form/refine-quote1-form.component";
import {CurrencyMaskModule} from "../directives/currency-mask-directive/currency-mask.module";
import {PhoneMaskDirective} from "../directives/phone-mask-directive/phone-mask.directive";
import {QuoteConfirmComponent} from "./forms/quote-confirm/quote-confirm.component";
import {GooglePlacesDirective} from "../directives/google-place.directive";
import {RefineQuote2FormComponent} from "./forms/refine-quote2-form/refine-quote2-form.component";
import {DateMaskDirective} from "../directives/date-mask-directive/date-mask-directive";
import {ThigAddressFormComponent} from "./forms/address-form/thig-address-form/thig-address-form.component";
import {QuickQuoteRoutingModule} from "./quick-quote-routing.module";
import {QuickQuoteComponent} from "./quick-quote.component";
import {HomeownersWorkFlowComponent} from "./homeowners-work-flow/homeowners-work-flow.component";
import {RentersWorkFlowComponent} from "./renters-work-flow/renters-work-flow.component";
import {CondoWorkFlowComponent} from "./condo-work-flow/condo-work-flow.component";
import {LandlordWorkFlowComponent} from "./landlord-work-flow/landlord-work-flow.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        QuickQuoteRoutingModule,
        MaterialModule,
        CurrencyMaskModule
    ],
    declarations: [
        ThigAddressAutocompleteComponent,
        ThigAddressFormComponent,
        QuickQuoteComponent,
        HomeownersWorkFlowComponent,
        RentersWorkFlowComponent,
        CondoWorkFlowComponent,
        LandlordWorkFlowComponent,
        AddressFormComponent,
        RatingFormComponent,
        ContactInfoFormComponent,
        RefineQuote1FormComponent,
        RefineQuote2FormComponent,
        QuoteConfirmComponent,
        PhoneMaskDirective,
        DateMaskDirective,
        GooglePlacesDirective
    ],
    exports: [
        ThigAddressAutocompleteComponent,
        ThigAddressFormComponent,
        MaterialModule,
        CurrencyMaskModule,
        PhoneMaskDirective,
        DateMaskDirective,
        AddressFormComponent,
        GooglePlacesDirective,
        RatingFormComponent,
        ContactInfoFormComponent,
        RefineQuote1FormComponent,
        RefineQuote2FormComponent,
        QuoteConfirmComponent
    ],
    entryComponents: [
        AddressFormComponent
    ]
})

export class QuickQuoteModule { }
