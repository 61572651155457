import {QuoteParameters} from "./quote-parameters";
import {RateResponse} from "./rate-response";
import {ContactParameters} from "./contact-parameters";

export class QuoteRequest {
    quoteParameters: QuoteParameters;
    contactParameters: ContactParameters;
    rateResponse: RateResponse;
    policyNum: String;
    form: String;
    zipcode: String;

    constructor() {
        this.quoteParameters = new QuoteParameters();
        this.contactParameters = new ContactParameters();
        this.rateResponse = null;
        this.policyNum = null;
        this.form = null;
        this.zipcode = null;
    }
}
