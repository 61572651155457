import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, timeout, finalize } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.service';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import {Messages} from "../../model/constants/messages";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    count = 0;
    defaultSpinnerInterval = 0.4;
    defaultTimeout = 100;

    constructor(public errorHandlerService: ErrorHandlerService, private spinner: SpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeoutValue = Number(request.headers.get('timeout')) || this.defaultTimeout;
        const spinnerInterval = Number(request.headers.get('spinnerInterval')) || this.defaultSpinnerInterval;
        const token: string = localStorage.getItem('token');
        setTimeout(() => {
            this.count++;
            if (this.count >= 1) {
                // console.log('http-interceptor request', request);
                request.url.includes('ratePolicy') ?
                    this.spinner.startBackendAction(Messages.RATING_SPINNER_MESSAGE) :
                    this.spinner.startBackendAction();
            }
        }, spinnerInterval * 1000);

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            timeout(timeoutValue * 1000),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ?
                        error.error.reason : error.message ? error.message : 'Error in your request',
                    status: error.status
                };
                console.error('ERROR:', data);
                // this.errorHandlerService.errorBar(data);
                this.errorHandlerService.handleError(data);
                return throwError(error);
            }),
            finalize(() => {
                this.count--;
                if ( this.count === 0 ) { this.spinner.endBackendAction(); }
            })
        );
    }
}
