import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from "@angular/core";
import {AddressOptionStore} from "../../../services/address-option-store";
import {ThigAddress} from "../../../model/thig-address";
import {AlertService} from "../../../core/alert/alert.service";
import {UtilsService} from "../../../core/utils.service";
import {Messages} from "../../../model/constants/messages";
import {AppSettings} from "../../../model/constants/app-settings";
import {WorkflowService} from "../../workflow.service";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddressFormComponent implements OnInit, OnDestroy {
  addressForm: FormGroup;
  isIE = AppSettings.IS_IE;
  private subscriptions = new Subscription();

  constructor(private addressOptionStore: AddressOptionStore,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private alertService: AlertService,
              private utils: UtilsService,
              private workflowService: WorkflowService,
              private storeService: QuickQuoteStoreService) {
  }

  ngOnInit(): void {
    this.buildAddressForm();
    this.subscriptions.add(this.addressOptionStore.addressOptions.subscribe(res => {
        // console.log('addressOptionStore.addressOptions updated', res);
      })
    );
  }

  buildAddressForm() {
    this.addressForm = this.formBuilder.group({
      "address": ['', Validators.required]
    });
  }

  validateAddress(place: ThigAddress) {
    if (place.street.includes('undefined') || this.utils.isEmpty(place.street)) {
      this.alertService.error(Messages.ADDRESS_ERROR);
      return;
    }
    this.addressOptionStore.validateAddress(place);
  }

  onAddressSelected(address: ThigAddress) {
    this.addressForm.controls['address'].setValue(address);
    this.alertService.clear();
    // stop here if form is invalid
    if (!this.storeService.validateForm(this.addressForm, Messages.ADDRESS_ERROR)) return;
    this.storeService.updateAddress(this.addressForm.value);
    this.workflowService.triggerNext();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
