import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from "@angular/core";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {RateResponse} from "../../../model/rate-response";
import {QuoteRequest} from "../../../model/quote-request";
import {AppSettings} from "../../../model/constants/app-settings";
import {Router} from "@angular/router";

@Component({
  selector: 'app-quote-confirm',
  templateUrl: './quote-confirm.component.html',
  styleUrls: ['./quote-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class QuoteConfirmComponent implements OnInit, AfterViewInit {
  thigUrl = AppSettings.THIG_URL;
  quoteRequest: QuoteRequest = new QuoteRequest();
  rateResponse: RateResponse;
  policyNum: String = '';
  formType: string;

  constructor(private quickQuoteStoreService: QuickQuoteStoreService,
              private router: Router,) {
    this.formType = this.router.getCurrentNavigation().extras.state.formType;
  }

  ngOnInit(): void {
    const quoteRequest: QuoteRequest = this.quickQuoteStoreService.quoteRequest.getValue();
    this.rateResponse = quoteRequest.rateResponse;
    this.policyNum = quoteRequest.policyNum;
    window.scrollTo(0, 0);
  }

  loadScript() {
    let node = document.createElement('script'); // creates the script tag
    node.text = "window.dataLayer = window.dataLayer || [];\n" +
      "window.dataLayer.push({\n" +
      "'event': 'QQ4 - Submission',\n" +
      "'QuoteID': '" + this.policyNum + "',\n" +
      "'Product': '" + this.formType + "',\n" +
      "'Currency': 'USD',\n" +
      "'Premium': " + this.rateResponse.premiumRange.lowerBound + ",\n" +
      "'Quantity': 1\n" +
      "});";
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngAfterViewInit() {
    this.loadScript()
  }
}
