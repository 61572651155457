import {InjectionToken, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {QuickQuoteComponent} from './quick-quote/quick-quote.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ErrorPageComponent} from "./core/error-handler/error-page/error-page.component";

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./quick-quote/quick-quote.module').then((m) => m.QuickQuoteModule)
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'externalRedirect',
    canActivate: [externalUrlProvider],
    // We need a component here because we cannot define the route otherwise
    component: NotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        const target = route.paramMap.get('target') ? route.paramMap.get('target') : '_self';
        window.open(externalUrl, target);
      },
    }
  ],
})
export class AppRoutingModule {
}
