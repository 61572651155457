export class Messages {
    public static DATE_ERROR = 'Please enter a valid date (MM/DD/YYYY).';
    public static FUTURE_DATE_ERROR = 'Please enter a date (MM/DD/YYYY) today\'s date or later.';
    public static YEAR_ERROR = 'Please enter a valid year (YYYY).';
    public static SELECT_ERROR = 'Please select a value.';
    public static BUILDERS_ERROR = 'Please enter home builder\'s name.';
    public static COVERAGE_A_ERROR = 'Please enter a valid coverage limit amount.';
    public static COVERAGE_C_ERROR = 'Please enter a valid amount.';
    public static FIRST_NAME_ERROR = 'Please enter your first name.';
    public static LAST_NAME_ERROR = 'Please enter your last name.';
    public static EMAIL_ERROR = 'Please enter a valid email address.';
    public static PHONE_ERROR = 'Please enter a valid phone number.';
    public static ADDRESS_ERROR = 'Invalid address. Please select from list.';
    public static FORM_ERROR = 'There are invalid or incomplete form entries. Please make corrections to continue.';
    public static SERVER_ERROR = 'The application is experiencing technical difficulties. Please try again later.';
    public static REQUIRED_ERROR = 'This field is required.';

    public static RATING_SPINNER_MESSAGE = 'Rating in progress. Please stand by...';
    public static STANDARD_SPINNER_MESSAGE = 'Processing...';
}
