export class AppSettings {
    public static THIG_URL = 'https://www.thig.com';
    public static CONTACT_AGENT_URL = 'https://www.thig.com/contact-agent';
    public static PRIVACY_STATEMENT_URL = 'https://www.thig.com/privacy-statement-security';
    public static GOOGLE_MAPS_API_URL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAi6Qq-IY1_YY20CS7LRlew0F6llm-Qaw4&libraries=places&language=en';

    public static CUSTOMER_CARE_PHONE_NUMBER = '800.342.3407';
    public static CUSTOMER_SERVICE_EMAIL = 'quickquote@thig.com';

    public static DEFAULT_AGENCY = '8444';
    public static DEFAULT_FORM = 'H3';

    //https://stackoverflow.com/questions/56379067/how-do-i-support-internet-explorer-in-an-angular-8-application
    public static IS_IE = /msie\s|trident\//i.test(window.navigator.userAgent);
}
