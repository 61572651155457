import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {QuickQuoteComponent} from "./quick-quote.component";
import {AddressFormComponent} from "./forms/address-form/address-form.component";
import {RatingFormComponent} from "./forms/rating-form/rating-form.component";
import {ContactInfoFormComponent} from "./forms/contact-info-form/contact-info-form.component";
import {RefineQuote1FormComponent} from "./forms/refine-quote1-form/refine-quote1-form.component";
import {RefineQuote2FormComponent} from "./forms/refine-quote2-form/refine-quote2-form.component";
import {QuoteConfirmComponent} from "./forms/quote-confirm/quote-confirm.component";


const routes: Routes = [
  {
    path: '',
    component: QuickQuoteComponent,
    children: [
      { path: '', redirectTo: 'address', pathMatch: 'full' },
      {
        path: 'address',
        component: AddressFormComponent
      },
      {
        path: 'rating',
        component: RatingFormComponent
      },
      {
        path: 'contact',
        component: ContactInfoFormComponent
      },
      {
        path: 'refineQuote1',
        component: RefineQuote1FormComponent
      },
      {
        path: 'refineQuote2',
        component: RefineQuote2FormComponent
      },
      {
        path: 'quoteConfirm',
        component: QuoteConfirmComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuickQuoteRoutingModule { }
