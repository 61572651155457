import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
    selector: 'app-dialog-popover',
    templateUrl: './dialog-popover.component.html',
    styleUrls: ['./dialog-popover.component.scss'],
})
export class DialogPopoverComponent {
    formControlName: String = this.data.formControlName;

    constructor(
        public dialogRef: MatDialogRef<DialogPopoverComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    onCloseClick(): void {
        this.dialogRef.close();
    }

}
