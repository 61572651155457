import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DialogPopoverComponent} from "../../../dialog-popover/dialog-popover.component";
import {MatDialog} from "@angular/material";
import {CONSTRUCTION_TYPES, ROOFING_MATERIAL, TOWNHOUSE_OR_ROWHOUSE_TYPES} from "../../../model/dropdown-enums";
import {RESIDENCE_TYPES} from "../../../model/dropdown-enums";
import {OCCUPIED_BY_TYPES} from "../../../model/dropdown-enums";
import {Messages} from "../../../model/constants/messages";
import {WorkflowService} from "../../workflow.service";
import {AlertService} from "../../../core/alert/alert.service";
import {QuickQuoteStoreService} from "../../../services/quick-quote-store.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {QuoteRequest} from "../../../model/quote-request";
import {RateResponse} from "../../../model/rate-response";

@Component({
  selector: 'app-rating-form',
  templateUrl: './rating-form.component.html',
  styleUrls: ['./rating-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RatingFormComponent implements OnInit, OnDestroy {

  ratingForm: FormGroup;
  formType: string;
  constructionTypes = CONSTRUCTION_TYPES;
  residenceTypes = RESIDENCE_TYPES;
  townhouseOrRowhouseTypes = TOWNHOUSE_OR_ROWHOUSE_TYPES;
  occupiedByTypes = OCCUPIED_BY_TYPES;
  roofMaterials = ROOFING_MATERIAL;
  coverageAErrorMessage = Messages.COVERAGE_A_ERROR;
  coverageCErrorMessage = Messages.COVERAGE_C_ERROR;
  selectErrorMessage = Messages.SELECT_ERROR;
  yearErrorMessage = Messages.YEAR_ERROR;
  private subscriptions = new Subscription();
  private numberPattern = '^[0-9.,]+$';
  rateResponse: RateResponse;

  constructor(private dialog: MatDialog,
              private formBuilder: FormBuilder,
              private router: Router,
              private workflowService: WorkflowService,
              private storeService: QuickQuoteStoreService,
              private alertService: AlertService) {
    this.formType = this.router.getCurrentNavigation().extras.state.formType;
  }

  ngOnInit() {
    this.buildRatingForm();
    const quoteRequest: QuoteRequest = this.storeService.quoteRequest.getValue();
    this.rateResponse = quoteRequest.rateResponse;
    this.ratingForm.patchValue(this.storeService.quoteRequest.getValue().quoteParameters);
    window.scrollTo(0, 0);
  }

  buildRatingForm() {
    this.ratingForm = this.formBuilder.group({
      "constructionYear": ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern(this.numberPattern)]],
      "constructionType": ['', [Validators.required]],
      "roofReplaced": ['false'],
      "roofYear": ['', ''],
    });
    if (this.formType !== 'H4') {
      this.ratingForm.addControl("roofReplaced", new FormControl('false', []));

      this.ratingForm.addControl("roofYear", new FormControl('', []));

      this.ratingForm.addControl("coverageA", new FormControl('', [
        Validators.required,
        Validators.pattern(this.numberPattern)]));
    }

    if (this.formType === 'H4' || this.formType === 'H6') {
      this.ratingForm.addControl("coverageC", new FormControl('', [
        Validators.required,
        Validators.pattern(this.numberPattern)]));
    }

    if (this.formType === 'D3') {
      this.ratingForm.addControl("residenceType", new FormControl('',[
        Validators.required]));

      this.ratingForm.addControl("townhouseOrRowhouse", new FormControl('',[]));
    }

    if (this.formType === 'H6' || this.formType === 'D3') {
      this.ratingForm.addControl("roofingMaterial", new FormControl('', [
        Validators.required]));
    }

    if (this.formType === 'H6') {
      this.ratingForm.addControl("occupiedBy", new FormControl('', [
        Validators.required]));
    }

    this.setRoofYearValidators();
    this.setTownhouseOrRowhouseValidators();
  }

  setRoofYearValidators() {
    const roofYearControl = this.ratingForm.get('roofYear');
    const roofReplacedControl = this.ratingForm.get('roofReplaced');

    this.subscriptions.add(
      roofReplacedControl.valueChanges.subscribe(val => {
        if (val === 'true') {
          roofYearControl.setValidators([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern(this.numberPattern)]);
        } else {
          roofYearControl.setValidators(null);
          roofYearControl.setValue(null);
        }
        roofYearControl.updateValueAndValidity();
      })
    )
  }

  setTownhouseOrRowhouseValidators() {
    const townhouseOrRowhouseControl = this.ratingForm.get('townhouseOrRowhouse');
    const residenceTypeControl = this.ratingForm.get('residenceType');

    this.subscriptions.add(
      residenceTypeControl.valueChanges.subscribe(val => {
        if (val === 'T') {
          townhouseOrRowhouseControl.setValidators([
            Validators.required]);
        } else {
          townhouseOrRowhouseControl.setValidators(null);
          townhouseOrRowhouseControl.setValue(null);
        }
        townhouseOrRowhouseControl.updateValueAndValidity();
      })
    )
  }

  nextStep(): void {
    this.alertService.clear();
    // stop here if form is invalid
    if (!this.storeService.validateForm(this.ratingForm, Messages.FORM_ERROR)) return;
    this.ratingForm.value['roofYear'] = this.ratingForm.value['roofYear'] === '' ?
      this.ratingForm.value['constructionYear'] : this.ratingForm.value['roofYear'];
    this.subscriptions.add(
      this.storeService.updateQuoteParameters(this.ratingForm.value, true)
        .subscribe((data: any) => {
          this.workflowService.triggerNext();
        })
    )
  }

  previousStep(): void {
    this.workflowService.triggerPrev();
  }

  openPopOver(formControlName: String): void {
    this.dialog.open(DialogPopoverComponent, {
      maxWidth: '450px',
      data: {formControlName: formControlName}
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
