export class ContactParameters {
    emailAddress: String;
    firstName: String;
    lastName: String;
    phoneNumber: String;
    preferredContactMethod: String;
    preferredContactTime: String;

    constructor() {
        this.emailAddress = '';
        this.firstName = '';
        this.lastName = '';
        this.phoneNumber = '';
        this.preferredContactMethod = 'EMAIL';
        this.preferredContactTime = 'MORNING';
    }

}
