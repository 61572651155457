import {ThigAddress} from "./thig-address";

export class AddressOption {
    isStandardized: boolean;
    thigAddress: ThigAddress;

    constructor(isStandardized, thigAddress) {
        this.isStandardized = isStandardized;
        this.thigAddress = thigAddress;
    }
}
